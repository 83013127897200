/*!
Pure v1.0.1
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE.md
*/
/*csslint regex-selectors:false, known-properties:false, duplicate-properties:false*/

.pure-g {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    *letter-spacing: normal; /* reset IE < 8 */
    *word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

    /*
    Sets the font stack to fonts known to work properly with the above letter
    and word spacings. See: https://github.com/pure-css/pure/issues/41/

    The following font stack makes Pure Grids work on all known environments.

    * FreeSans: Ships with many Linux distros, including Ubuntu

    * Arimo: Ships with Chrome OS. Arimo has to be defined before Helvetica and
      Arial to get picked up by the browser, even though neither is available
      in Chrome OS.

    * Droid Sans: Ships with all versions of Android.

    * Helvetica, Arial, sans-serif: Common font stack on OS X and Windows.
    */
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

    /* Use flexbox when possible to avoid `letter-spacing` side-effects. */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;

    /* Prevents distributing space between rows */
    -ms-flex-line-pack: start;
        align-content: flex-start;
}

/* IE10 display: -ms-flexbox (and display: flex in IE 11) does not work inside a table; fall back to block and rely on font hack */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	table .pure-g {
		display: block;
	}
}

/* Opera as of 12 on Windows needs word-spacing.
   The ".opera-only" selector is used to prevent actual prefocus styling
   and is not required in markup.
*/
.opera-only :-o-prefocus,
.pure-g {
    word-spacing: -0.43em;
}

.pure-u {
    display: inline-block;
    *display: inline; /* IE < 8: fake inline-block */
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*
Resets the font family back to the OS/browser's default sans-serif font,
this the same font stack that Normalize.css sets for the `body`.
*/
.pure-g [class *= "pure-u"] {
    font-family: sans-serif;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

.pure-u-1-24 {
    width: 4.1667%;
    *width: 4.1357%;
}

.pure-u-1-12,
.pure-u-2-24 {
    width: 8.3333%;
    *width: 8.3023%;
}

.pure-u-1-8,
.pure-u-3-24 {
    width: 12.5000%;
    *width: 12.4690%;
}

.pure-u-1-6,
.pure-u-4-24 {
    width: 16.6667%;
    *width: 16.6357%;
}

.pure-u-1-5 {
    width: 20%;
    *width: 19.9690%;
}

.pure-u-5-24 {
    width: 20.8333%;
    *width: 20.8023%;
}

.pure-u-1-4,
.pure-u-6-24 {
    width: 25%;
    *width: 24.9690%;
}

.pure-u-7-24 {
    width: 29.1667%;
    *width: 29.1357%;
}

.pure-u-1-3,
.pure-u-8-24 {
    width: 33.3333%;
    *width: 33.3023%;
}

.pure-u-3-8,
.pure-u-9-24 {
    width: 37.5000%;
    *width: 37.4690%;
}

.pure-u-2-5 {
    width: 40%;
    *width: 39.9690%;
}

.pure-u-5-12,
.pure-u-10-24 {
    width: 41.6667%;
    *width: 41.6357%;
}

.pure-u-11-24 {
    width: 45.8333%;
    *width: 45.8023%;
}

.pure-u-1-2,
.pure-u-12-24 {
    width: 50%;
    *width: 49.9690%;
}

.pure-u-13-24 {
    width: 54.1667%;
    *width: 54.1357%;
}

.pure-u-7-12,
.pure-u-14-24 {
    width: 58.3333%;
    *width: 58.3023%;
}

.pure-u-3-5 {
    width: 60%;
    *width: 59.9690%;
}

.pure-u-5-8,
.pure-u-15-24 {
    width: 62.5000%;
    *width: 62.4690%;
}

.pure-u-2-3,
.pure-u-16-24 {
    width: 66.6667%;
    *width: 66.6357%;
}

.pure-u-17-24 {
    width: 70.8333%;
    *width: 70.8023%;
}

.pure-u-3-4,
.pure-u-18-24 {
    width: 75%;
    *width: 74.9690%;
}

.pure-u-19-24 {
    width: 79.1667%;
    *width: 79.1357%;
}

.pure-u-4-5 {
    width: 80%;
    *width: 79.9690%;
}

.pure-u-5-6,
.pure-u-20-24 {
    width: 83.3333%;
    *width: 83.3023%;
}

.pure-u-7-8,
.pure-u-21-24 {
    width: 87.5000%;
    *width: 87.4690%;
}

.pure-u-11-12,
.pure-u-22-24 {
    width: 91.6667%;
    *width: 91.6357%;
}

.pure-u-23-24 {
    width: 95.8333%;
    *width: 95.8023%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-24-24 {
    width: 100%;
}
/*!
Pure v1.0.1
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE.md
*/
.pure-button {
    /* Structure */
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    box-sizing: border-box;
}

/* Firefox: Get rid of the inner focus border */
.pure-button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

/* Inherit .pure-g styles */
.pure-button-group {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    *letter-spacing: normal; /* reset IE < 8 */
    *word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */
}

.opera-only :-o-prefocus,
.pure-button-group {
    word-spacing: -0.43em;
}

.pure-button-group .pure-button {
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*csslint outline-none:false*/

.pure-button {
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    color: #444; /* rgba not supported (IE 8) */
    color: rgba(0, 0, 0, 0.80); /* rgba supported */
    border: 1px solid #999;  /*IE 6/7/8*/
    border: none rgba(0, 0, 0, 0);  /*IE9 + everything else*/
    background-color: #E6E6E6;
    text-decoration: none;
    border-radius: 2px;
}

.pure-button-hover,
.pure-button:hover,
.pure-button:focus {
    background-image: linear-gradient(transparent, rgba(0,0,0, 0.05) 40%, rgba(0,0,0, 0.10));
}
.pure-button:focus {
    outline: 0;
}
.pure-button-active,
.pure-button:active {
    box-shadow: 0 0 0 1px rgba(0,0,0, 0.15) inset, 0 0 6px rgba(0,0,0, 0.20) inset;
    border-color: #000;
}

.pure-button[disabled],
.pure-button-disabled,
.pure-button-disabled:hover,
.pure-button-disabled:focus,
.pure-button-disabled:active {
    border: none;
    background-image: none;
    /* csslint ignore:start */
    filter: alpha(opacity=40);
    /* csslint ignore:end */
    opacity: 0.40;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
}

.pure-button-hidden {
    display: none;
}

.pure-button-primary,
.pure-button-selected,
a.pure-button-primary,
a.pure-button-selected {
    background-color: rgb(0, 120, 231);
    color: #fff;
}

/* Button Groups */
.pure-button-group .pure-button {
    margin: 0;
    border-radius: 0;
    border-right: 1px solid #111;  /* fallback color for rgba() for IE7/8 */
    border-right: 1px solid rgba(0, 0, 0, 0.2);

}

.pure-button-group .pure-button:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.pure-button-group .pure-button:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: none;
}

