/*!
Pure v1.0.1
Copyright 2013 Yahoo!
Licensed under the BSD License.
https://github.com/pure-css/pure/blob/master/LICENSE.md
*/
/*csslint regex-selectors:false, known-properties:false, duplicate-properties:false*/

.pure-g {
    letter-spacing: -0.31em; /* Webkit: collapse white-space between units */
    *letter-spacing: normal; /* reset IE < 8 */
    *word-spacing: -0.43em; /* IE < 8: collapse white-space between units */
    text-rendering: optimizespeed; /* Webkit: fixes text-rendering: optimizeLegibility */

    /*
    Sets the font stack to fonts known to work properly with the above letter
    and word spacings. See: https://github.com/pure-css/pure/issues/41/

    The following font stack makes Pure Grids work on all known environments.

    * FreeSans: Ships with many Linux distros, including Ubuntu

    * Arimo: Ships with Chrome OS. Arimo has to be defined before Helvetica and
      Arial to get picked up by the browser, even though neither is available
      in Chrome OS.

    * Droid Sans: Ships with all versions of Android.

    * Helvetica, Arial, sans-serif: Common font stack on OS X and Windows.
    */
    font-family: FreeSans, Arimo, "Droid Sans", Helvetica, Arial, sans-serif;

    /* Use flexbox when possible to avoid `letter-spacing` side-effects. */
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;

    /* Prevents distributing space between rows */
    -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
            align-content: flex-start;
}

/* IE10 display: -ms-flexbox (and display: flex in IE 11) does not work inside a table; fall back to block and rely on font hack */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	table .pure-g {
		display: block;
	}
}

/* Opera as of 12 on Windows needs word-spacing.
   The ".opera-only" selector is used to prevent actual prefocus styling
   and is not required in markup.
*/
.opera-only :-o-prefocus,
.pure-g {
    word-spacing: -0.43em;
}

.pure-u {
    display: inline-block;
    *display: inline; /* IE < 8: fake inline-block */
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

/*
Resets the font family back to the OS/browser's default sans-serif font,
this the same font stack that Normalize.css sets for the `body`.
*/
.pure-g [class *= "pure-u"] {
    font-family: sans-serif;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-1-2,
.pure-u-1-3,
.pure-u-2-3,
.pure-u-1-4,
.pure-u-3-4,
.pure-u-1-5,
.pure-u-2-5,
.pure-u-3-5,
.pure-u-4-5,
.pure-u-5-5,
.pure-u-1-6,
.pure-u-5-6,
.pure-u-1-8,
.pure-u-3-8,
.pure-u-5-8,
.pure-u-7-8,
.pure-u-1-12,
.pure-u-5-12,
.pure-u-7-12,
.pure-u-11-12,
.pure-u-1-24,
.pure-u-2-24,
.pure-u-3-24,
.pure-u-4-24,
.pure-u-5-24,
.pure-u-6-24,
.pure-u-7-24,
.pure-u-8-24,
.pure-u-9-24,
.pure-u-10-24,
.pure-u-11-24,
.pure-u-12-24,
.pure-u-13-24,
.pure-u-14-24,
.pure-u-15-24,
.pure-u-16-24,
.pure-u-17-24,
.pure-u-18-24,
.pure-u-19-24,
.pure-u-20-24,
.pure-u-21-24,
.pure-u-22-24,
.pure-u-23-24,
.pure-u-24-24 {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
}

.pure-u-1-24 {
    width: 4.1667%;
    *width: 4.1357%;
}

.pure-u-1-12,
.pure-u-2-24 {
    width: 8.3333%;
    *width: 8.3023%;
}

.pure-u-1-8,
.pure-u-3-24 {
    width: 12.5000%;
    *width: 12.4690%;
}

.pure-u-1-6,
.pure-u-4-24 {
    width: 16.6667%;
    *width: 16.6357%;
}

.pure-u-1-5 {
    width: 20%;
    *width: 19.9690%;
}

.pure-u-5-24 {
    width: 20.8333%;
    *width: 20.8023%;
}

.pure-u-1-4,
.pure-u-6-24 {
    width: 25%;
    *width: 24.9690%;
}

.pure-u-7-24 {
    width: 29.1667%;
    *width: 29.1357%;
}

.pure-u-1-3,
.pure-u-8-24 {
    width: 33.3333%;
    *width: 33.3023%;
}

.pure-u-3-8,
.pure-u-9-24 {
    width: 37.5000%;
    *width: 37.4690%;
}

.pure-u-2-5 {
    width: 40%;
    *width: 39.9690%;
}

.pure-u-5-12,
.pure-u-10-24 {
    width: 41.6667%;
    *width: 41.6357%;
}

.pure-u-11-24 {
    width: 45.8333%;
    *width: 45.8023%;
}

.pure-u-1-2,
.pure-u-12-24 {
    width: 50%;
    *width: 49.9690%;
}

.pure-u-13-24 {
    width: 54.1667%;
    *width: 54.1357%;
}

.pure-u-7-12,
.pure-u-14-24 {
    width: 58.3333%;
    *width: 58.3023%;
}

.pure-u-3-5 {
    width: 60%;
    *width: 59.9690%;
}

.pure-u-5-8,
.pure-u-15-24 {
    width: 62.5000%;
    *width: 62.4690%;
}

.pure-u-2-3,
.pure-u-16-24 {
    width: 66.6667%;
    *width: 66.6357%;
}

.pure-u-17-24 {
    width: 70.8333%;
    *width: 70.8023%;
}

.pure-u-3-4,
.pure-u-18-24 {
    width: 75%;
    *width: 74.9690%;
}

.pure-u-19-24 {
    width: 79.1667%;
    *width: 79.1357%;
}

.pure-u-4-5 {
    width: 80%;
    *width: 79.9690%;
}

.pure-u-5-6,
.pure-u-20-24 {
    width: 83.3333%;
    *width: 83.3023%;
}

.pure-u-7-8,
.pure-u-21-24 {
    width: 87.5000%;
    *width: 87.4690%;
}

.pure-u-11-12,
.pure-u-22-24 {
    width: 91.6667%;
    *width: 91.6357%;
}

.pure-u-23-24 {
    width: 95.8333%;
    *width: 95.8023%;
}

.pure-u-1,
.pure-u-1-1,
.pure-u-5-5,
.pure-u-24-24 {
    width: 100%;
}